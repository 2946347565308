import React, { useState } from 'react';
import Card from '../UI/card/Card';
import { Formik, FormikErrors } from 'formik';
import Input from '../UI/input/Input';
import Button from '../UI/button/Button';
import styles from './SearchReservation.module.css';
import VectorError from '../../assets/images/Vector.png';
import DatePickerField from '../UI/datePickerField/DatePickerField';
import { FormValues, IApiParametersValues } from '../../interfaces';
import { paginationConstants } from '../../utils/contants';

interface IOnSubmitInput {
  setSubmitting: (flag: boolean) => void;
}

interface ISearchReservation {
  getBookingReservationDetailsHandler: (values: IApiParametersValues) => void;
}

const SearchReservation = ({ getBookingReservationDetailsHandler }: ISearchReservation) => {
  const [isEmptyFields, setIsEmptyFields] = useState(false);
  const [isSearchInputWithOutEmailInput, setIsSearchInputWithOutEmailInput] = useState(false);
  const validate = (values: FormValues) => {
    setIsEmptyFields(false);
    const errors: FormikErrors<FormValues> = {};
    // validation for reservationId
    if (
      values.reservationId !== '' &&
      !/^(?=(?:\D*\d){10}\D*$)[\d\s.]+$/i.test(values.reservationId.toString())
    ) {
      errors.reservationId = 'isInvalid';
    }

    // validation for email address
    if (!values.emailAddress) {
      errors.emailAddress = 'emailIsEmpty';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.emailAddress)) {
      errors.emailAddress = 'isInvalid';
    }

    // validation for first name
    if (
      values.firstName !== '' &&
      !/^(?=.{1,50}$)[a-z]+(?:['_.\s][a-z]+)*$/i.test(values.firstName)
    ) {
      errors.firstName = 'isInvalid';
    }

    // validation for last name
    if (
      values.lastName !== '' &&
      !/^(?=.{1,50}$)[a-z]+(?:['_.\s][a-z]+)*$/i.test(values.lastName)
    ) {
      errors.lastName = 'isInvalid';
    }

    // validation for hotel id
    if (values.hotelId !== '' && !/\b\d{1,20}\b/g.test(values.hotelId)) {
      errors.hotelId = 'isInvalid';
    }
    return errors;
  };
  const onSubmit = async (values: FormValues, { setSubmitting }: IOnSubmitInput) => {
    setIsSearchInputWithOutEmailInput(false);
    if (
      !values.reservationId &&
      !values.lastName &&
      !values.firstName &&
      !values.hotelId &&
      !values.emailAddress &&
      !values.checkInDate &&
      !values.checkOutDate
    ) {
      setIsEmptyFields(true);
    } else if (
      !values.reservationId &&
      !values.lastName &&
      !values.firstName &&
      !values.hotelId &&
      !values.checkInDate &&
      !values.checkOutDate
    ) {
      setIsSearchInputWithOutEmailInput(true);
    } else {
      const apiValues = {
        ...values,
        pageNumber: paginationConstants.pageNumber,
        sortOrder: paginationConstants.sortOrder,
        sortField: paginationConstants.sortField,
        pageLimit: paginationConstants.pageLimit
      };
      getBookingReservationDetailsHandler(apiValues);
    }
    setSubmitting(false);
  };
  return (
    <Card className={styles.showReservationContainer}>
      <h3>
        <strong>Find reservations</strong>
      </h3>
      <p className={styles.findInfoReservationText}>
        Search for reservations by reservation ID. If no reservation ID is available, you can also
        search by email address, customer first and last name, or hotel ID and check-in/out date:
      </p>

      {isEmptyFields && (
        <div className={styles.emptyContainer}>
          <img src={VectorError} alt={'errorInput'} />
          <span>You have not entered any search criteria!</span>
        </div>
      )}
      {isSearchInputWithOutEmailInput && (
        <div className={styles.emptyContainer}>
          <img src={VectorError} alt={'errorInput'} />
          <span>You have to enter one or more search input with email address!</span>
        </div>
      )}
      <Formik
        initialValues={{
          reservationId: '',
          emailAddress: '',
          firstName: '',
          lastName: '',
          hotelId: '',
          checkInDate: null,
          checkOutDate: null
        }}
        validate={validate}
        onSubmit={onSubmit}>
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
          resetForm
        }) => (
          <form onSubmit={handleSubmit}>
            <div className={styles.reservationInputContainer}>
              <Input
                data-testid={'reservationId'}
                label={'Reservation ID'}
                id="reservationId"
                name="reservationId"
                type={'text'}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.reservationId}
                errorType={errors.reservationId}
                touched={touched.reservationId}
              />
              <Input
                data-testid={'emailAddress'}
                label={'Email address'}
                id="emailAddress"
                name="emailAddress"
                type={'text'}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.emailAddress}
                errorType={errors.emailAddress}
                touched={touched.emailAddress}
              />
              <Input
                data-testid={'firstName'}
                label={'First name'}
                id="firstName"
                name="firstName"
                type={'text'}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.firstName}
                errorType={errors.firstName}
                touched={touched.firstName}
              />
              <Input
                data-testid={'lastName'}
                label={'Last name'}
                id="lastName"
                name="lastName"
                type={'text'}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.lastName}
                errorType={errors.lastName}
                touched={touched.lastName}
              />
            </div>
            <div className={styles.hotelInputContainer}>
              <Input
                data-testid={'hotelId'}
                className={styles.hotelInput}
                label={'Hotel ID'}
                id="hotelId"
                name="hotelId"
                type={'text'}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.hotelId}
                errorType={errors.hotelId}
                touched={touched.hotelId}
              />
              <DatePickerField
                id="checkInDate"
                label={'Check-in date'}
                name={'checkInDate'}
                value={values.checkInDate}
                onChange={setFieldValue}
                maxDate={values.checkOutDate && new Date(values.checkOutDate)}
              />
              <DatePickerField
                dateFieldClassName={styles.checkoutAlignment}
                id="checkOutDate"
                label={'Check-out date'}
                name={'checkOutDate'}
                value={values.checkOutDate}
                onChange={setFieldValue}
                minDate={values.checkInDate && new Date(values.checkInDate)}
              />
            </div>
            <Button
              className={styles.searchReservationButton}
              type={'submit'}
              disabled={isSubmitting}>
              Search for reservations
            </Button>
            <Button className={styles.clearSearch} onClick={() => resetForm()} type={'button'}>
              Clear Search
            </Button>
          </form>
        )}
      </Formik>
    </Card>
  );
};

export default SearchReservation;
